<template>
	<div>
		<section class="header-bg" style="min-height: 255px;">
		</section>

		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">ABOUT US
					</strong></p>
			</div>
		</section>

		<section class="container-1200" style="margin-top: 80px;">
			<!-- <div class="block">&nbsp;Introduction</div> -->
			<div class="introduction">
				<div class="introduction_content">
					<div style="font-weight:bold;font-size:30px;margin-bottom:10px;">What is Contrimetric?</div>
					<div lang="en"
						style="text-align: justify; hyphens: auto;line-height: 24px;font-weight:500;text-indent: 26px;font-size: 16px;">
						<p>Contrimetric is a newly proposed concept of bibliometrics, derived from Contribution Metric.
							It is also demonstrated by a specific indicator called Article Citation Contribution
							Indicator(ACCI).</p>
						<p>The ACCl value is one of the article level metrics and calculated with the
							journal impact factor of each journal as the benchmark.</p>
						<p>When a paper gets a higher citation than the average of all the papers in the same year, it
							has a positive ACCI value of the
							year.</p>
					</div>
				</div>
				<div class="introduction_img">
					<img :src="require('@/assets/images/aboutR.png')" alt="">
				</div>
			</div>
		</section>
		<section class="container-1200" style="margin: 40px auto;">
			<div class="block" style="width:100%;font-size:30px;text-align: center;">&nbsp;Q&A</div>
			<div style="text-align: justify;line-height: 30px;font-size: 16px;padding: 10px" class="layui-collapse">


				<div class="layui-colla-item">
					<p class="layui-colla-title" @click="aboutShow = !aboutShow">1.&nbsp;How do you calculate your ACCI Value?
						<i class="layui-icon layui-colla-icon" v-if="aboutShow"></i>
						<i class="layui-icon layui-colla-icon" v-else></i>
					</p>
					<div :class="aboutShow?'layui-colla-content layui-show':'layui-colla-content'">
						<div style="text-align: left;margin-top: 20px">
							<img src="../assets/images/about.png" alt="">
						</div>

						<article>
							Cy, the number of citations in the JCR year;
						</article>
						<article style="margin-top: 25px;">
							JIFy, the value of journal impact factor;
						</article>
						y=0, publishing year of the paper; C0, the number of citations in the publishing year; JIF0, the
						value of journal impact factor in publishing year, then we can get ACCI0;
						<article style="margin-top: 25px;">
							y=1, the first year after the publishing year of the paper; C1, the number of citations in
							the first year after the publishing year，JIF1, the value of journal impact factor in the
							first year after the publishing year, then we can get ACCI1;
						</article>
						<article style="margin-top: 25px;">
							y=2, the second year after the publishing year of the paper; C2, the number of citations in
							the second year after the publishing year，JIF2, the value of journal impact factor in the
							second year after the publishing year, then we can get ACCI2;
						</article>
						<article style="margin-top: 25px;">
							ACCI>0，the paper contributes to journal impact factor；ACCI<0，the paper pulls down the
								journal impact factor. </article>

					</div>
				</div>
				<div class="layui-colla-item">
					<p class="layui-colla-title" @click="aboutShowA = !aboutShowA">2.&nbsp;Why do some papers have the badge,
						while others not?
						<i class="layui-icon layui-colla-icon" v-if="aboutShowB"></i>
						<i class="layui-icon layui-colla-icon" v-else></i>
					</p>
					<div :class="aboutShowA?'layui-colla-content layui-show':'layui-colla-content'">

						<article>
							Only the paper with a positive contribution factor value is qualified with a badge, and the
							value is filled in the blank, citations in the brackets. If the paper has a negative ACCI
							value, the badge will not be assigned and the paper will not be included by the Contrimetirc
							database.
						</article>
					</div>


				</div>


				<div class="layui-colla-item">
					<p class="layui-colla-title" @click="aboutShowB = !aboutShowB">3.&nbsp;What is the difference between
						Altmetric and Contrimetric?
						<i class="layui-icon layui-colla-icon" v-if="aboutShowB"></i>
						<i class="layui-icon layui-colla-icon" v-else></i>
					</p>
					<div :class="aboutShowB?'layui-colla-content layui-show':'layui-colla-content'">

						<article>
							Altmetric and Contrimetric are both tools for measuring the impact of individual academic
							papers. Altmetric is a tool for measuring the influence of papers published from a broader
							perspective, which includes blog, twitter, policy maker and so on in addition to citation.
							Contrary to Altmetric, the Contrametric is a measure of the relative influence of scientific
							articles only in terms of the citation and average citation level of the papers, namely JIF.
							It is a new indicator to measure in a narrower range. The ACCI value of some papers are the
							most favorable tool for the journal editorial department.
						</article>
						<article style="margin-top: 10px;">
							In order to explore the influence of the author's single article, we will take the perodical
							as the community to investigate and build a single paper citation contribution measurement
							index. Journal editors can also judge the impact of an author's previously published
							articles in their disciplinary community based on their historical citation contribution.
						</article>
					</div>


				</div>

				<div class="layui-colla-item">
					<p class="layui-colla-title" @click="aboutShowC = !aboutShowC">4.&nbsp;What is the citation performance
						overall publications?
						<i class="layui-icon layui-colla-icon" v-if="aboutShowC"></i>
						<i class="layui-icon layui-colla-icon" v-else></i>
					</p>
					<div :class="aboutShowC?'layui-colla-content layui-show':'layui-colla-content'">

						<article>
							Obviously, if the papers published in the journal generate more citations in the second and
							third years, it will also play a greater role in the calculation of journal impact factors
							and the better the image of the journal. It is estimated that only about 23% articles from
							the journals in JCR Quatile 1 have positive ACCI value and 77% articles have negetaive
							effect on their JIF. In another word, a few papers bring "positive contributions" to the
							impact factors of journals, while most papers bring "negative contributions" .
						</article>
						<article style="margin-top: 20px;">
							For example, the positive and negative values of ACCI are the most significant reference
							values for the measurement of papers. The higher the proportion of papers with positive
							ACCI, the higher JIF the journal will receive.
						</article>
						<article style="margin-top: 20px;">
							Each paper has two ACCI values, namely CF1 and CF2. The CF1 represents the contribution
							value of the second year after the publication of the paper, while the CF2 represents the
							contribution value of the third year after published.
						</article>
					</div>


				</div>

				<div class="layui-colla-item">
					<p class="layui-colla-title" @click="aboutShowD = !aboutShowD">
						5.&nbsp;What paper are collected by Contrimetric.com?
						<i class="layui-icon layui-colla-icon" v-if="aboutShowD"></i>
						<i class="layui-icon layui-colla-icon" v-else></i>
					</p>
					<div :class="aboutShowD?'layui-colla-content layui-show':'layui-colla-content'">

						<article>
							The website contrimetric.com will collect papers with positive CF. If you find your missing
							paper, you may visit Correct CF and editor will contact you. The contrimetric is no-profit
							organization and free of charge when you apply for some services.
						</article>
						<article style="margin-top: 20px;">
							The Contrimetric covers publication from 2016-now when all terms meet the requirement.
						</article>
					</div>


				</div>
				
				<div class="layui-colla-item">
					<p class="layui-colla-title" @click="aboutShowE = !aboutShowE">
						6.&nbsp;Publications in Contrimetrics
						<i class="layui-icon layui-colla-icon" v-if="aboutShowD"></i>
						<i class="layui-icon layui-colla-icon" v-else></i>
					</p>
					<div :class="aboutShowE?'layui-colla-content layui-show':'layui-colla-content'">
				
						<article>
							<div><a href="https://www.cambridge.org/core/journals/cns-spectrums/article/contrimetric-article-citation-contribution-indicator-for-paper-impact-evaluation/EC9A50C3567FE673582DD3473591EA8B">1. Contrimetric: Article Citation Contribution Indicator for paper impact evaluation</a></div>
							<div  style="margin-left:15px"><span style="font-weight:bold">Author: </span> Kristine Kuo</div>
							<div style="margin-left:15px">CNS Spectrums &nbsp; 27 October, 2023</div>
							
						</article>
						<article style="margin-top: 20px;">
							<div><a href="https://efcc.com.my/efcc-02-2022-81-84/">2. ARTICLE CITATION CONTRIBUTION INDICATOR: APPLICATION IN ENERGY AND ENVIRONMENT</a></div>
							<div  style="margin-left:15px"><span style="font-weight:bold">Author: </span> Zhenling Liu</div>
							<div style="margin-left:15px">ECOFEMINISM AND CLIMATE CHANGE &nbsp; 2022</div>
							
						</article>
					</div>
				
				
				</div>

			</div>
		</section>
	</div>

</template>
<script>
	export default {
		props: ['searchVal'],
		data() {
			return {
				aboutShow: true,
				aboutShowA: false,
				aboutShowB: false,
				aboutShowC: false,
				aboutShowD:false,
				aboutShowE:false,
			};
		},
		methods: {

		},

	};
</script>

<style lang="scss" scoped>
	.introduction {
		display: flex;
	}

	.introduction_content {
		flex: 1;
		padding: 20px;

	}

	.introduction_img {
		flex: 1;
		padding: 20px;
	}

	.block {
		/* width: 140px;
		padding:5px 10px;
		border: 1px solid black;
		border-radius: 20px; */
		margin-bottom: 20px;
		font-weight: bold;
		font-size: 20px;
	}
</style>